import '@stitch-fix/kufak-fe-infra/src/babel/core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Header, Footer } from '@stitch-fix/knit';
// import { initI18n } from '@stitch-fix/i18n';
import '../styles/knit.scss';

// Knit translates text and links based on UK or US, but requires configuration
const regionLocaleMap = {
  UK: {
    region: 'UK',
    locale: 'en-GB',
    root_url: 'https://www.stitchfix.co.uk',
  },
  US: {
    region: 'US',
    locale: 'en-US',
    root_url: 'https://www.stitchfix.com',
  },
};

const userLocation = window.location.href;
const SITE = userLocation.includes('support.stitchfix.co.uk') ? 'UK' : 'US';

if (process.env.NODE_ENV === 'development' || process.env.ENABLE_MOCKS) {
  // require is synchronous, which allows MSW to be initialized before the app renders,
  // see: https://github.com/mswjs/msw/discussions/1236
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const { worker } = require('../mocks/msw/browser');

  worker.start();
}

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Header
      rootUrl={regionLocaleMap[SITE].root_url}
      region={regionLocaleMap[SITE].region}
    />,

    document.getElementById('knit-header'),
  );

  ReactDOM.render(
    <Footer
      rootUrl={regionLocaleMap[SITE].root_url}
      region={regionLocaleMap[SITE].region}
    />,

    document.getElementById('knit-footer'),
  );
});
